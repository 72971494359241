body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.App {
    text-align: center;
    font-size: 12px;
}
.App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}
.btn-logout, .btn-submit {
    background-color: #f44336;
    color: #ffffff;
    padding: 15px;
}
.row {
    margin-left: 0px;
    margin-right: 0px;
}
.btn-logout {
    width: 100px;
    font-size: 14px;
}
.form-control {
    font-size: 14px;
    height: 30px;
    padding: 5px;
}
@-webkit-keyframes App-logo-spin {
    from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes App-logo-spin {
    from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
.page-wrapper {
    font-size: 14px;
}

#root .color-dark {
  color: #778CA2;
}

#root .color-black {
  color: #212529;
}

#root .line-height-15 {
  line-height: 15px;
}

#root .line-height-16 {
  line-height: 16px;
}

#root .line-height-24 {
  line-height: 24px;
}
/* html { min-height: 100%; position: absolute; top: 0; bottom: 0; left: 0; right: 0; overflow:hidden;}
body { min-height: 100%; overflow:hidden;} */

.scrollbar-custom {
  overflow: auto;
}

.scrollbar-custom::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scrollbar-custom::-webkit-scrollbar:vertical {
  width: 0px;
}

.scrollbar-custom::-webkit-scrollbar:horizontal {
  height: 0px;
}

.scrollbar-custom:hover::-webkit-scrollbar:vertical {
  width: 7px;
}

.scrollbar-custom:hover::-webkit-scrollbar:horizontal {
  height: 7px;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 1px solid transparent; /* should match background, can't be transparent */
  background-color: #5B5B5B;
}

.scrollbar-custom::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
}

.bg-black {
  background-color: #000;
}

.bg-black.opacity-08 {
  background-color: rgba(0,0,0,0.6);
}

.opacity-08 {
  opacity: 0.8
}

.bg-submit {
  background-color: #f44336;
}
button.logout-btn {
  background: none;
  border: none;
  padding: 0;
  color: #007BFF; }

button.logout-btn:hover {
  text-decoration: underline;
  color: #0056B3; }


.checkbox label {
  padding-left: 0px;
}
.checkbox {
  margin-top: -5px;
}
.forgot-password {
  float: right;
}
#checkbox-signup {
  margin-left: 0px;
}
#lb-checkbox-signup {
  margin-left: 20px
}
.btn-submit {
  width: 215px;
  font-size: 16px;
  font-weight: bold;
}
.zen-logo-login img {
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}
.group-button {
  margin-top: 30px;
}
.login-form {
  float: none;
  margin: 0 auto;
  padding-top: 200px;
  font-size: 14px;
}
.copyright {
  margin-top: 200px;
}
.popup-wrong-role {
  padding-left: 50px;
  padding-right: 50px;
  max-width: 500px;
}
.popup-wrong-role .submit {
  width: 150px;
}

.login-steps {
  max-width: 160px;
}

.login-again-btn {
  background-color: rgba(0,0,0,0.6);
  width: 150px;
}
.new-list-call {
  height: 100%;
  width: 100%; }

.sidebar {
  /* width: 15%; */
  border-right: 1px solid #E8ECEF;
  /* float: left; */
  position: absolute;
  /* top: 0px; */
  left: -230px;
  height: 100%;
  width: 300px;
  -webkit-transition: left .3s ease-in-out;
  transition: left .3s ease-in-out; }

.content {
  position: absolute;
  background: #F8FAFB;
  /* top: 0; */
  left: 70px;
  right: 0;
  height: 100%;
  -webkit-transition: left .3s ease-in-out;
  transition: left .3s ease-in-out; }

.header {
  width: 100%;
  height: 8%;
  border-bottom: 1px solid #E8ECEF;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background: #FFFFFF;
  font-size: 20px; }
  .header .operator-name {
    color: #F4364C;
    text-align: right;
    height: 100%; }
  .header .search-for-listing .searh-form {
    font-size: 16px;
    outline: none;
    height: 50px; }
    .header .search-for-listing .searh-form:focus {
      box-shadow: none; }
  .header .search-for-listing .la {
    font-size: 24px; }
    .header .search-for-listing .la.la-search {
      line-height: 40px; }

.zen-logo-new {
  width: 50%;
  margin-left: 20%;
  margin-right: 35%;
  margin-top: 30px;
  margin-bottom: 40px; }

.zen-logo-small {
  float: right;
  width: 50px;
  height: 50px;
  margin-left: 240px;
  margin-top: 15px;
  margin-bottom: 40px; }

.left-230 {
  margin-left: 230px; }

.header svg {
  width: 50px !important;
  height: 20px;
  color: #778CA2; }

.header-title, .operator-name {
  color: #252631;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left; }

.list-place .operator-name {
  color: white; }

.operator-name:hover, .operator-name:focus {
  outline: none !important;
  box-shadow: none !important; }

.sidebar-open {
  left: 0; }

.content-open {
  left: 300px; }

.sidebar ul {
  padding: 0px;
  list-style-type: none; }

.sidebar ul li {
  /* background-color: #F8FAFB; */
  height: 64px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  padding-left: 20px;
  color: #778CA2;
  font-size: 14px;
  font-weight: 400; }

.sidebar ul li:hover {
  background-color: #F8FAFB; }

.li-select {
  border-left: 2px solid #F4364C;
  background-color: #F8FAFB;
  font-weight: 500 !important; }

.li-select .la::before {
  color: #F4364C !important; }

.tab-content {
  padding: 20px;
  height: 92%;
  background-color: #F8FAFB; }

.hidden {
  display: none; }

.tab-title {
  color: #778CA2;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 15px; }

.select {
  background-color: #F4364C !important;
  color: #FFFFFF !important; }

.btn-calling-type {
  background-color: #F8FAFB;
  width: 144.76px;
  height: 35.22px;
  color: #778CA2;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  border-radius: 5px;
  padding: 3.75px 7.5px; }

.incoming-call hr {
  margin-top: 20px; }

.table-list-calls .thead {
  color: #98A9BC;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 10px; }

.table-list-calls .tvalue {
  background-color: #FFFFFF;
  min-height: 60px;
  border-radius: 4px;
  margin-bottom: 10px;
  color: #252631;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }
  .table-list-calls .tvalue .text-center {
    word-break: break-word; }

.btn-accept-call, .btn-detail-call {
  height: 34px;
  padding: 0;
  border: none;
  color: transparent;
  background-color: transparent;
  outline: none;
  display: block; }

.btn-accept-call {
  float: left;
  background-color: #6DD230;
  width: 80%;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  border-radius: 5px; }

.type-checkin, .type-support {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  width: 121px;
  height: 34px;
  margin: 0 auto;
  padding: 8px; }

.type-support {
  color: #4D7CFE;
  background-color: #F0F4FE; }

.type-checkin {
  color: #FE4D97;
  background-color: #FFF1F6; }

.la {
  font-family: LineAwesome;
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  text-align: center; }

.la-phone::before, .la-history::before {
  color: #778CA2;
  float: right; }

.la-info-circle::before {
  color: #252631;
  font-family: LineAwesome;
  font-size: 42px;
  font-weight: 500;
  line-height: 50px; }

.right-20 {
  margin-right: 20px; }

.btn-group {
  width: 100%; }

.btn-detail-call {
  float: right;
  margin-top: -9px;
  margin-left: 10px; }
  .btn-detail-call.disabled {
    opacity: 0.3; }

.btn-detail-call-center {
  margin-top: -9px;
  margin-left: 10px;
  border: none;
  background: none; }
  .btn-detail-call-center.disabled {
    opacity: 0.3;
    pointer-events: none; }

.pagination .prev-text, .pagination .next-text {
  text-align: center;
  width: 60px !important;
  padding-left: 0px;
  padding-right: 0px;
  font-size: 14px;
  color: #778CA2;
  font-family: Roboto;
  font-weight: 400;
  line-height: 17px;
  padding-top: 2px; }

.pagination > li {
  width: 40px; }

.pagination > li > a {
  border: none !important;
  color: #000000; }

.la-angle-left, .la-angle-right {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #778CA2;
  font-family: LineAwesome;
  text-align: center; }

.prev-li {
  margin-right: 30px; }

.pagination .active a {
  color: #FFFFFF;
  background-color: #F4364C;
  border-radius: 4px;
  width: 40px;
  text-align: center; }

.pagination > .active > a:hover, .pagination > .active > a:focus {
  background-color: #F4364C; }

.pagination-call {
  width: 350px;
  margin-left: auto;
  margin-right: auto; }

.popup-list-call {
  padding-top: 40px;
  padding-left: 50px;
  padding-right: 50px;
  max-width: 500px; }

.user-menu ul {
  font-size: 14px;
  margin-top: 23px;
  text-align: center;
  left: auto !important;
  right: 0 !important;
  -webkit-transform: translate3d(-7px, 20px, 0px) !important;
          transform: translate3d(-7px, 20px, 0px) !important; }

.missed-time {
  font-size: 15px; }

.text-loading {
  font-size: 15px; }

.noti-bar {
  background: #2F3036;
  color: #FFFFFF;
  padding: 10px;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.noti-bar span {
  margin-right: 20px; }

.btn-noti-call {
  margin-right: 15px; }

.btn-noti-call img {
  width: 40px;
  height: 40px; }

.time-range-filter, .call-type-filter, .status-filter, .reservation-status-filter, .checkin-status-filter, .tablet-location-filter {
  margin: auto; }
  .time-range-filter .dropdown-toggle:after, .call-type-filter .dropdown-toggle:after, .status-filter .dropdown-toggle:after, .reservation-status-filter .dropdown-toggle:after, .checkin-status-filter .dropdown-toggle:after, .tablet-location-filter .dropdown-toggle:after {
    content: none; }

.col.text-center.nocall-noti {
  color: #98a9bc;
  font: 20px Roboto;
  padding-top: 50px;
  font-weight: bold; }

.popup-remopve-place {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 100px;
  padding-right: 100px;
  border: 1px solid;
  background-color: #fff; }

.popup-remopve-place h2 {
  text-align: center;
  color: #616161;
  font-weight: bold;
  margin-bottom: 15px; }

.popup-remopve-place .body {
  text-align: center;
  margin-bottom: 30px; }

.popup-remopve-place .submit {
  background-color: #f44336;
  color: #fff;
  font-size: 14px;
  margin-right: 20px;
  font-weight: bold; }

.popup-remopve-place .cancel {
  border: 0.8px solid #616161;
  font-size: 14px;
  width: 108px; }

.detailed-item {
  padding: 20px;
  height: 92%;
  background-color: #F8FAFB; }
  .detailed-item .row.detailed-item-header {
    height: 8%;
    background-color: white;
    border-bottom: 1px solid #e5e5e5; }
    .detailed-item .row.detailed-item-header .svg-inline--fa.fa-w-14 {
      font-size: 20px; }
  .detailed-item .detailed-item-content {
    height: 92%; }

.call-history {
  margin-bottom: 20px;
  height: calc(100% - 120px);
  background-color: #FFFFFF; }
  .call-history .text-center {
    word-break: break-word; }
  .call-history .detail-content {
    float: left;
    width: calc(100% - 293px);
    height: 100%;
    overflow-y: auto; }
  .call-history .right-tab {
    float: right;
    width: 293px;
    border-left: 1px solid #F2F4F6;
    height: 100%; }
    .call-history .right-tab ul {
      padding-left: 0px; }
  .call-history .right-tab li {
    color: #252631;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    height: 70px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    padding-left: 20px; }
  .call-history .la-user, .call-history .la-building-o, .call-history .la-map-pin, .call-history .la-arrow-left {
    color: #778CA2;
    font-family: LineAwesome;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px; }
  .call-history .la-arrow-left {
    margin-right: 20px; }
  .call-history .right-tab .li-select {
    color: #F4364C;
    background-color: #F8FAFB;
    border-left: none; }
  .call-history .listing-name {
    height: 70px;
    color: #252631;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    -webkit-align-items: center;
            align-items: center;
    padding-left: 20px; }
  .call-history .detail-content hr {
    margin-top: 0px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    color: #F2F4F6; }
  .call-history .table-guest-infos .thead {
    padding-bottom: 10px; }
  .call-history .call-history-detail .tvalue {
    color: #252631;
    font-size: 16px;
    font-weight: 400; }
  .call-history .table-guest-infos .thead {
    color: #98A9BC;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px; }
  .call-history .table-guest-infos .tvalue {
    font-size: 18px;
    margin-bottom: 15px; }
  .call-history .reservation-infos, .call-history .listing-infos, .call-history .table-guest-infos {
    margin-top: 25px; }
    .call-history .reservation-infos .reservation-datetime-info, .call-history .listing-infos .reservation-datetime-info, .call-history .table-guest-infos .reservation-datetime-info {
      font-size: 24px; }
  .call-history .listing-infos .col-md-12, .call-history .listing-infos .col-lg-12 {
    margin-bottom: 15px; }
  .call-history .reservation-infos .col-md-6, .call-history .reservation-infos .col-lg-6 {
    margin-bottom: 50px !important; }
  .call-history .listing-infos .col-md-6, .call-history .reservation-infos .col-lg-6 {
    margin-bottom: 50px !important; }
  .call-history .listing-infos .title {
    color: #98A9BC;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px; }
  .call-history .listing-infos .value {
    color: #252631;
    font-size: 24px; }
  .call-history .tab-detail {
    padding-left: 15px;
    padding-right: 17px;
    height: calc(100% - 82px); }
    .call-history .tab-detail .table-guest-infos {
      margin-top: 0; }
      .call-history .tab-detail .table-guest-infos .col-value {
        font-size: 14px;
        font-weight: 300; }
  .call-history .table td, .call-history .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    font-size: 18px; }
  .call-history .table-guest-infos .thead {
    color: #98A9BC;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px; }
  .call-history .table-guest-infos .tvalue {
    font-size: 18px;
    margin-bottom: 15px; }
  .call-history table.call-log-list th {
    color: #aab8c8;
    font-size: 14px; }
  .call-history table.call-log-list td .checkin {
    width: 80%;
    height: 80%;
    text-align: center;
    padding: 5px;
    background-color: #f6ebf4;
    color: #f95da1;
    font-size: 14px; }
  .call-history table.call-log-list td .support {
    width: 80%;
    height: 80%;
    text-align: center;
    padding: 5px;
    background-color: #f1f5ff;
    color: #7da0ff;
    font-size: 14px; }
  .call-history table.call-log-list ul {
    padding-left: 0px;
    list-style-type: none; }
  .call-history .no-data, .call-history .load-data {
    text-align: center;
    margin-top: 30px;
    color: #98a9bc;
    font: bold 20px Roboto; }

.incoming-call .anonymous-guest {
  color: #FFCB2C; }

.rdrDefinedRangesWrapper {
  width: 166px; }
  .rdrDefinedRangesWrapper .rdrStaticRanges {
    position: relative;
    height: 100%; }
  .rdrDefinedRangesWrapper .rdrStaticRange:last-child span {
    background-color: #F4364C;
    color: white;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center; }

.rdrDateRangeWrapper {
  font-size: 12px;
  font-family: 'Roboto'; }
  .rdrDateRangeWrapper .rdrMonth {
    width: 250px; }
  .rdrDateRangeWrapper .rdrStartEdge, .rdrDateRangeWrapper .rdrEndEdge, .rdrDateRangeWrapper .rdrInRange {
    border-radius: 0 !important; }
  .rdrDateRangeWrapper .rdrInRange {
    opacity: 0.2; }
  .rdrDateRangeWrapper .rdrInRange ~ .rdrDayNumber span {
    color: black !important;
    font-weight: bold; }
  .rdrDateRangeWrapper .rdrDayPassive .rdrInRange ~ .rdrDayNumber span {
    font-weight: normal;
    color: #aeb9bf !important; }
  .rdrDateRangeWrapper .rdrMonthAndYearWrapper {
    background-color: #F8FAFB; }
    .rdrDateRangeWrapper .rdrMonthAndYearWrapper .rdrNextPrevButton, .rdrDateRangeWrapper .rdrMonthAndYearWrapper .rdrPprevButton {
      background: transparent;
      color: #98A9BC;
      outline: none; }
      .rdrDateRangeWrapper .rdrMonthAndYearWrapper .rdrNextPrevButton i, .rdrDateRangeWrapper .rdrMonthAndYearWrapper .rdrPprevButton i {
        font: normal normal normal 16px/1 LineAwesome;
        font-family: LineAwesome;
        font-size: 22px;
        font-weight: 500;
        line-height: 0;
        border: none; }
        .rdrDateRangeWrapper .rdrMonthAndYearWrapper .rdrNextPrevButton i::before, .rdrDateRangeWrapper .rdrMonthAndYearWrapper .rdrPprevButton i::before {
          content: "\F111"; }
    .rdrDateRangeWrapper .rdrMonthAndYearWrapper .rdrPprevButton {
      -webkit-transform: translate(-10px, 0) !important;
              transform: translate(-10px, 0) !important; }
    .rdrDateRangeWrapper .rdrMonthAndYearWrapper .rdrNextButton {
      -webkit-transform: translate(-4px, 0) !important;
              transform: translate(-4px, 0) !important; }
      .rdrDateRangeWrapper .rdrMonthAndYearWrapper .rdrNextButton i::before {
        content: "\F112"; }
    .rdrDateRangeWrapper .rdrMonthAndYearWrapper .rdrMonthAndYearPickers {
      font-size: 18px;
      font-weight: 400; }

.history-call .search-filter-group .time-range-filter #dateRangeDropdown {
  left: auto;
  right: 0; }

.history-call .search-filter-group .calling-type-list {
  -webkit-transform: translate3d(0, 23px, 0px) !important;
          transform: translate3d(0, 23px, 0px) !important;
  transform: translate3d(0, 23px, 0px) !important;
  padding: 0;
  left: auto !important;
  right: 0; }
  .history-call .search-filter-group .calling-type-list .dropdown-item {
    padding: 15px; }
    .history-call .search-filter-group .calling-type-list .dropdown-item:not(:first-child) {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0; }

.history-call .history-calls-container {
  height: 100%; }
  .history-call .history-calls-container .table-list-calls {
    height: 93%; }
    .history-call .history-calls-container .table-list-calls .table-list-content {
      height: 95%;
      overflow-y: auto; }

.history-call .table-list-calls .thead {
  color: #98A9BC;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 10px; }

.history-call .table-list-calls .tvalue {
  background-color: #FFFFFF;
  min-height: 60px;
  border-radius: 4px;
  margin-bottom: 10px;
  color: #252631;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }
  .history-call .table-list-calls .tvalue .text-center {
    word-break: break-word; }

.history-call .anonymous-guest {
  color: #FFCB2C; }

.reservations-list.tab-content {
  padding: 20px;
  height: 92%;
  background-color: #F8FAFB; }

.reservations-list-container {
  height: 100%; }

.table-reservations {
  height: 95%; }
  .table-reservations .table-content {
    height: 95%;
    overflow-y: auto; }
    .table-reservations .table-content .tvalue.row.high-light {
      background-color: #fef2ca; }
  .table-reservations .thead {
    color: #98A9BC;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 10px; }
  .table-reservations .tvalue {
    background-color: #FFFFFF;
    min-height: 60px;
    border-radius: 4px;
    margin-bottom: 10px;
    color: #252631;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center; }
    .table-reservations .tvalue .text-center {
      word-break: break-word; }
  .table-reservations .res-status {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
    width: 121px;
    height: 34px;
    margin: 0 auto;
    padding: 8px;
    width: 70%; }
  .table-reservations .res-status.checkin {
    background-color: #f4fcef;
    color: #77d53e; }
  .table-reservations .res-status.not {
    color: #FE4D97;
    background-color: #FFF1F6; }
  .table-reservations .btn-action-call {
    background-color: #6DD230;
    width: 120px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    border-radius: 5px;
    outline: none;
    display: block;
    height: 34px;
    padding: 0;
    border: none; }
  .table-reservations .btn-action-call.disabled {
    background-color: #c0c7bb; }
  .table-reservations .action-reservation-detail {
    margin-top: -9px;
    margin-left: 5px;
    border: none;
    background: none; }
    .table-reservations .action-reservation-detail .la-info-circle::before {
      color: #252631;
      font-family: LineAwesome;
      font-size: 42px;
      font-weight: 500;
      line-height: 50px; }
  .table-reservations .res-not-found {
    color: #98a9bc;
    font: 20px Roboto;
    padding-top: 50px;
    font-weight: bold; }

.reservation-alert {
  position: relative;
  font-family: Roboto;
  text-align: center;
  width: 650px;
  padding: 40px;
  background: #ffffff;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23); }
  .reservation-alert .title {
    margin: 30px;
    font-size: 20px; }
  .reservation-alert .btn-cancel {
    position: absolute;
    top: 0px;
    right: 0px;
    background: none;
    border: none; }
    .reservation-alert .btn-cancel .la.la-close {
      color: black; }
  .reservation-alert .btn-call {
    width: 160px;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    border: none; }
  .reservation-alert .btn-call.checkin {
    background: red; }
  .reservation-alert .btn-call.checkin[disabled] {
    background-color: brown;
    cursor: wait; }
  .reservation-alert .btn-call.support {
    background: #4A7DDC; }
  .reservation-alert .btn-call.support[disabled] {
    background-color: #687fa9;
    cursor: wait; }

.nav-noti-group {
  max-height: 64px; }

.noti-bar {
  position: fixed;
  width: 100%;
  z-index: 2222; }
  .noti-bar .noti-group-btn .take-phone-icon {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg); }

.my-node-enter {
  max-height: 0; }

.my-node-enter-active {
  max-height: 64px; }

.my-node-exit {
  max-height: 64px; }

.my-node-exit-active {
  max-height: 0;
  -webkit-transition: max-height 500ms ease-out;
  transition: max-height 500ms ease-out;
  padding: 0;
  overflow: hidden; }

.toggle-slider-button-container {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  /* Rounded sliders */ }
  .toggle-slider-button-container input {
    opacity: 0;
    width: 0;
    height: 0; }
  .toggle-slider-button-container .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s; }
  .toggle-slider-button-container .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s; }
  .toggle-slider-button-container input:checked + .slider {
    background-color: #2196F3; }
  .toggle-slider-button-container input:focus + .slider {
    box-shadow: 0 0 1px #2196F3; }
  .toggle-slider-button-container input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    transform: translateX(26px); }
  .toggle-slider-button-container .slider.round {
    border-radius: 34px; }
  .toggle-slider-button-container .slider.round:before {
    border-radius: 50%; }

.tablet-location-container {
  padding: 20px;
  height: 92%;
  background-color: #F8FAFB; }
  .tablet-location-container p {
    margin-bottom: 0; }
  .tablet-location-container .row.flash-messages {
    position: absolute;
    left: 5%;
    width: 90%;
    z-index: 1; }
    .tablet-location-container .row.flash-messages .alert {
      width: 100%;
      text-align: center;
      margin: 0; }
      .tablet-location-container .row.flash-messages .alert p {
        margin: 0; }
  .tablet-location-container .add-place {
    background-color: white; }
    .tablet-location-container .add-place .header-add-place {
      border-bottom: 1px solid #dee0e1;
      padding: 10px 0px 10px 0px;
      height: 7%; }
    .tablet-location-container .add-place .generate-code-btn {
      background: none;
      border: none; }
    .tablet-location-container .add-place .form-add-place {
      width: auto;
      padding-top: 30px;
      height: 93%; }
    .tablet-location-container .add-place .form-add-place .form-group {
      margin-bottom: 40px; }
    .tablet-location-container .add-place #place-type {
      width: 60%; }
    .tablet-location-container .add-place .input-place {
      width: 100% !important; }
    .tablet-location-container .add-place .setup-code {
      background: #616161;
      padding: 10px;
      padding-left: 20px;
      padding-right: 20px;
      color: white;
      margin-right: 10px; }
    .tablet-location-container .add-place #refresh-code {
      width: 25px; }
    .tablet-location-container .add-place .btn-place-type {
      width: 100px;
      font-size: 14px;
      background-color: white;
      color: black; }
    .tablet-location-container .add-place .btn-place-type.active {
      color: #fff;
      background-color: #0062cc;
      border-color: #005cbf; }
    .tablet-location-container .add-place .btn-place-type.disabled {
      background: rgba(0, 0, 0, 0.25);
      color: black; }
    .tablet-location-container .add-place .btn-add {
      width: 100px;
      background-color: #FA7268;
      padding: 10px;
      color: white;
      margin-right: 10px;
      margin-bottom: 40px;
      font-size: 14px; }
    .tablet-location-container .add-place .btn-clear {
      width: 100px;
      border: 1px solid;
      padding: 10px;
      margin-right: 10px;
      font-size: 14px; }
    .tablet-location-container .add-place .place-title {
      margin-bottom: 40px;
      color: #616161; }
    .tablet-location-container .add-place .add-place-title {
      font-weight: bold; }
  .tablet-location-container .edit-place {
    background-color: white; }
    .tablet-location-container .edit-place .generate-setup-code-btn {
      border: none;
      background: none; }
    .tablet-location-container .edit-place .cannot-edit-place {
      width: 50%;
      height: 93%;
      margin: auto;
      text-align: center;
      display: -webkit-flex;
      display: flex; }
      .tablet-location-container .edit-place .cannot-edit-place p {
        margin: auto;
        font-family: roboto;
        font-size: 20px;
        color: red;
        text-shadow: 2px -4px yellow; }
    .tablet-location-container .edit-place .header-edit-place {
      border-bottom: 1px solid #dee0e1;
      padding: 10px 0px 10px 0px;
      height: 7%;
      font-size: 20px; }
    .tablet-location-container .edit-place .form-add-place {
      width: auto;
      padding-top: 30px;
      height: 93%; }
    .tablet-location-container .edit-place .form-add-place .form-group {
      margin-bottom: 40px; }
    .tablet-location-container .edit-place #place-type {
      width: 60%; }
    .tablet-location-container .edit-place .input-place {
      width: 100% !important; }
    .tablet-location-container .edit-place .setup-code {
      background: #616161;
      padding: 10px;
      padding-left: 20px;
      padding-right: 20px;
      color: white;
      margin-right: 10px; }
    .tablet-location-container .edit-place #refresh-code {
      width: 25px; }
    .tablet-location-container .edit-place .btn-place-type {
      width: 100px;
      font-size: 14px;
      background-color: white;
      color: black; }
    .tablet-location-container .edit-place .btn-place-type.disabled {
      background: rgba(0, 0, 0, 0.25);
      color: black; }
    .tablet-location-container .edit-place .btn-add {
      width: 100px;
      background-color: #FA7268;
      padding: 10px;
      color: white;
      margin-right: 10px;
      margin-bottom: 40px;
      font-size: 14px; }
    .tablet-location-container .edit-place .btn-clear {
      width: 100px;
      border: 1px solid;
      padding: 10px;
      margin-right: 10px;
      font-size: 14px; }
    .tablet-location-container .edit-place .place-title {
      margin-bottom: 40px;
      color: #616161; }
    .tablet-location-container .edit-place .add-place-title {
      font-weight: bold; }
  .tablet-location-container .place-detail {
    overflow: auto;
    background-color: white;
    width: 100%; }
    .tablet-location-container .place-detail .header-place-detail {
      border-bottom: 1px solid #dee0e1;
      padding: 10px 0px 10px 0px;
      height: 7%;
      font-size: 20px; }
    .tablet-location-container .place-detail .place-detail-title {
      color: #616161;
      margin-bottom: 20px; }
    .tablet-location-container .place-detail .react-bootstrap-table th {
      background-color: #d4e0f7;
      color: #000000;
      border: 1px solid #e5ebef;
      font-size: larger; }
    .tablet-location-container .place-detail .react-bootstrap-table {
      padding: 30px; }
    .tablet-location-container .place-detail .edit-place-btn {
      margin-bottom: 30px;
      margin-right: 30px;
      background-color: #4a7ddc;
      color: white;
      border: none;
      width: 100px;
      height: 40px;
      border-radius: 7px; }
  .tablet-location-container #tablet-location-list {
    height: 100%; }
    .tablet-location-container #tablet-location-list .table-list {
      height: 88%; }
      .tablet-location-container #tablet-location-list .table-list .thead {
        color: #98A9BC;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin-bottom: 10px; }
      .tablet-location-container #tablet-location-list .table-list .tcontent {
        height: 99%;
        overflow-y: auto; }
      .tablet-location-container #tablet-location-list .table-list .tvalue {
        background-color: #FFFFFF;
        min-height: 60px;
        border-radius: 4px;
        margin-bottom: 10px;
        color: #252631;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-align-items: center;
                align-items: center; }
        .tablet-location-container #tablet-location-list .table-list .tvalue .text-center {
          word-break: break-word; }
      .tablet-location-container #tablet-location-list .table-list .btn-group button {
        border-radius: 50%;
        border: none;
        width: 40px;
        height: 40px;
        margin: 2px; }
      .tablet-location-container #tablet-location-list .table-list .btn-group .call-btn {
        background-color: #e5f7da; }
        .tablet-location-container #tablet-location-list .table-list .btn-group .call-btn svg {
          color: #6DD230; }
      .tablet-location-container #tablet-location-list .table-list .btn-group .edit-btn {
        background-color: #d4e0f7; }
        .tablet-location-container #tablet-location-list .table-list .btn-group .edit-btn svg {
          color: #4a7ddc; }
      .tablet-location-container #tablet-location-list .table-list .btn-group .delete-btn {
        background-color: #fee7e9; }
        .tablet-location-container #tablet-location-list .table-list .btn-group .delete-btn svg {
          color: #f4354b; }
      .tablet-location-container #tablet-location-list .table-list .tvalue.row.tablet-location-element:hover {
        border: 1px solid orangered;
        cursor: pointer; }
      .tablet-location-container #tablet-location-list .table-list .tvalue.row.tablet-location-element {
        border: 1px solid white; }
    .tablet-location-container #tablet-location-list .add-location-container {
      padding-left: 0px; }
    .tablet-location-container #tablet-location-list .row .col-md-6 .d-flex.float-right {
      width: 100%; }
    .tablet-location-container #tablet-location-list .searchbar .search-form {
      font-size: 16px;
      outline: none;
      height: 50px; }
      .tablet-location-container #tablet-location-list .searchbar .search-form:focus {
        box-shadow: none; }
    .tablet-location-container #tablet-location-list .searchbar .la {
      font-size: 24px; }
      .tablet-location-container #tablet-location-list .searchbar .la.la-search {
        line-height: 40px; }

.tablet-location-container.hidden {
  display: none; }

.tablet-location-alert {
  position: relative;
  font-family: Roboto;
  text-align: center;
  width: 650px;
  padding: 40px;
  background: #ffffff;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23); }
  .tablet-location-alert .title {
    margin: 30px;
    font-size: 20px; }
  .tablet-location-alert .btn-cancel {
    position: absolute;
    top: 0px;
    right: 0px;
    background: none;
    border: none; }
    .tablet-location-alert .btn-cancel .la.la-close {
      color: black; }
  .tablet-location-alert .btn-call {
    width: 160px;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    border: none; }
  .tablet-location-alert .btn-call.checkin {
    background: red; }
  .tablet-location-alert .btn-call.support {
    background: #4A7DDC; }

.popup-remove-place {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 100px;
  padding-right: 100px;
  border: 1px solid;
  background-color: #fff; }

.popup-remove-place h2 {
  text-align: center;
  color: #616161;
  font-weight: bold;
  margin-bottom: 15px; }

.popup-remove-place .body {
  text-align: center;
  margin-bottom: 30px; }

.popup-remove-place .submit {
  background-color: #f44336;
  color: #fff;
  font-size: 14px;
  margin-right: 20px;
  font-weight: bold; }

.popup-remove-place .confirm {
  background-color: #4A7DDC;
  color: #fff;
  font-size: 14px;
  margin-right: 20px;
  font-weight: bold; }

.popup-remove-place .cancel {
  border: 0.8px solid #616161;
  font-size: 14px;
  width: 108px; }

.no-data, .load-data {
  text-align: center;
  margin-top: 30px;
  color: #98a9bc;
  font: bold 20px Roboto; }

.call-detail {
  background: #FFFFFF;
  width: 100%;
  margin: 0px;
  height: 100vh;
  position: relative; }
  .call-detail .row.header {
    height: 7vh;
    margin-bottom: 0px; }
  .call-detail ul {
    padding: 0px;
    list-style-type: none; }
  .call-detail .btn-call-detail {
    background: #f8fafb;
    box-shadow: 0 0 0 1px #e4f2f9;
    height: 100%; }
  .call-detail .btn-call-detail button {
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
    border: none; }
  .call-detail .btn-call-detail button:focus {
    outline: 0;
    box-shadow: none; }
  .call-detail .btn-call-detail .btn-select {
    background-color: #C3D8E3; }
  .call-detail .btn-call-detail .btn i {
    vertical-align: middle; }
  .call-detail .no-left {
    border-left: none !important; }
  .call-detail .table-guest-infos .thead {
    color: #98A9BC;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px; }
  .call-detail .table-guest-infos .tvalue {
    font-size: 18px;
    margin-bottom: 15px; }
  .call-detail .table-guest-infos p {
    margin-bottom: 0px; }
  .call-detail .table-guest-infos .guest-occupation {
    color: #98A9BC; }
  .call-detail .reservation-infos, .call-detail .listing-infos, .call-detail .guests-infos {
    margin-top: 25px; }
    .call-detail .reservation-infos .reservation-datetime-info, .call-detail .listing-infos .reservation-datetime-info, .call-detail .guests-infos .reservation-datetime-info {
      font-size: 18px; }
  .call-detail .listing-infos .col-md-12, .call-detail .listing-infos .col-lg-12 {
    margin-bottom: 15px; }
  .call-detail .reservation-infos .col-md-6, .call-detail .reservation-infos .col-lg-6 {
    margin-bottom: 50px !important; }
  .call-detail .listing-infos .col-md-6, .call-detail .reservation-infos .col-lg-6 {
    margin-bottom: 50px !important; }
  .call-detail .listing-infos .title {
    color: #98A9BC;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin-bottom: 0; }
  .call-detail .listing-infos .value {
    color: #252631;
    font-size: 18px;
    margin-bottom: 0; }
  .call-detail .img-screenshot {
    width: 100%;
    height: 100%; }
  .call-detail .tab-detail {
    width: 100%;
    height: 93vh; }
    .call-detail .tab-detail .information-display, .call-detail .tab-detail .chat-display {
      display: block; }
    .call-detail .tab-detail .information-hidden, .call-detail .tab-detail .chat-hidden {
      display: none !important; }
    .call-detail .tab-detail .information-display {
      padding-left: 15px;
      padding-right: 15px;
      height: 100%; }
      .call-detail .tab-detail .information-display .no-information-state {
        margin: auto; }
        .call-detail .tab-detail .information-display .no-information-state p {
          text-align: center;
          margin-top: 20px;
          margin-bottom: 0px;
          font-size: 2vh; }
      .call-detail .tab-detail .information-display .anonymous-call-info {
        padding: 2rem; }
        .call-detail .tab-detail .information-display .anonymous-call-info h3 {
          margin-bottom: 2rem; }
        .call-detail .tab-detail .information-display .anonymous-call-info ul {
          padding-left: 30px;
          list-style: disc outside none;
          list-style: initial; }
        .call-detail .tab-detail .information-display .anonymous-call-info ul li {
          margin: 5px; }
    .call-detail .tab-detail .table-guest-infos {
      margin-top: 0; }
      .call-detail .tab-detail .table-guest-infos .col-value {
        font-size: 14px;
        font-weight: 300; }
  .call-detail .table td, .call-detail .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    font-size: 15px; }

.messenger {
  padding: 0px 15px 0px 15px;
  height: 93vh;
  background-color: #ffffff; }

.messages-wrapper {
  display: table-cell;
  vertical-align: bottom;
  height: 86vh;
  width: 25vw; }

.history-msg {
  position: relative;
  max-height: 86vh;
  overflow: auto; }

.line-msg {
  width: 100%;
  min-height: 70px;
  overflow: auto; }
  .line-msg .line-msg-container {
    max-width: 75%;
    height: 100%;
    border: none;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    word-break: break-word;
    margin-bottom: 8px; }
  .line-msg .line-msg-container.tablet {
    color: #ffffff;
    background-color: #5B5B5B;
    border-radius: 20px 20px 20px 0px; }
  .line-msg .line-msg-container.operator {
    color: black;
    background-color: #d2d2d2;
    border-radius: 20px 20px 0px 20px; }

.input-messages {
  width: 100%;
  height: 7vh;
  padding-top: 5px; }
  .input-messages .type-msg {
    border: none;
    padding: 5px;
    padding-left: 20px;
    height: 50px;
    color: #ffffff;
    background-color: #bebebe;
    float: left;
    width: 90%;
    border-radius: 10px 0px 0px 10px;
    padding-right: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px; }
  .input-messages .btn-send-message {
    height: 50px;
    float: left;
    width: 10%; }
    .input-messages .btn-send-message button {
      width: 100%;
      height: 100%;
      background-color: #bebebe;
      border: 0;
      border-radius: 0px 10px 10px 0px; }

.type-msg:focus {
  outline: none; }

.history-msg div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #333434;
  margin-left: 10px; }

.history-msg div::-webkit-scrollbar {
  width: 8px;
  background-color: #333434; }

.history-msg div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #8D8D8D; }

.msg-content {
  width: 100%;
  margin-bottom: 10px; }

.msg-time {
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  margin-right: 5px; }

.timer {
  display: inline;
  margin-right: 10px;
}
.row-1 .end-button-box {
  margin-top: 20px; }

#num-guets-verified {
  position: absolute;
  top: 50%;
  left: 110px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: yellow;
  font-weight: bold;
  font-size: 18px; }
  #num-guets-verified p {
    margin: 0px; }

.row-1 .guest-name {
  text-align: center;
  width: 100%;
  color: #FFFFFF;
  font-family: monospace;
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  margin: 0 auto;
  text-overflow: ellipsis; }

.row-1 .duration {
  text-align: center;
  width: 100%;
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 300;
  line-height: 26px;
  opacity: 0.6994512648809523;
  margin: 0 auto; }

.row-1 .verification-noti {
  position: absolute;
  right: 30px;
  top: 20px;
  padding: 0px;
  margin: 0px;
  color: white;
  padding: 10px;
  background-color: #35d698;
  border-radius: 5px; }

.row-1 .btn-end-call {
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: transparent;
  border: none;
  background-size: 100px auto;
  margin-top: 0px !important;
  text-align: left; }

.row-1 .end-button-box {
  margin-top: 17px; }

.row-1 .btn-end-call img {
  width: 60px;
  height: 60px; }

.row-1 .topbar-box {
  background-color: #2b2d2d;
  width: 100%;
  position: relative; }

.d-flex.button-box {
  margin-left: 45px; }

.row-1 button.swipe-call-details {
  background-color: #FFFFFF;
  border: none;
  position: absolute;
  right: 69px;
  top: 50%;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  padding: 0px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .row-1 button.swipe-call-details .la-comment {
    color: #303030; }

.microphone-unavailable {
  width: 241px;
  height: 30px;
  color: yellow;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: large; }

.microphone-unavailable.left {
  left: 10%; }

.microphone-unavailable.right {
  right: -4%; }

.flash-group {
  position: absolute;
  top: 20px;
  right: 140px;
  z-index: 2222; }
  .flash-group .verify-flash {
    padding: 5px 25px;
    border-radius: 4px;
    color: white;
    width: 220px; }
    .flash-group .verify-flash.failed {
      background-color: #FFC52C; }
    .flash-group .verify-flash.verified {
      background-color: green; }

.guest-item {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  width: 98%;
  height: 50px;
  font-size: 22px; }
  .guest-item.active {
    background-color: white;
    color: #4F5B65;
    font-weight: bold; }
  .guest-item .guest-status {
    font-size: 24px;
    line-height: 2; }

.guests-actions {
  color: #9A9A9A;
  font-weight: 300;
  padding: 30px 15px;
  height: 100%; }
  .guests-actions .title {
    font-size: 18px; }
  .guests-actions .guests-list {
    max-height: calc(100% - 25px);
    width: 60%; }
    .guests-actions .guests-list .guest-name {
      text-overflow: ellipsis;
      width: calc(100% - 32px);
      overflow: hidden;
      white-space: nowrap;
      float: right;
      line-height: 2.2; }
  .guests-actions .guests-list.sidebaropen {
    max-height: calc(100% - 25px);
    width: 50%; }
    .guests-actions .guests-list.sidebaropen .guest-name {
      text-overflow: ellipsis;
      width: calc(100% - 32px);
      overflow: hidden;
      white-space: nowrap;
      float: right;
      line-height: 2.2; }
  .guests-actions .verify-button-group {
    font-size: 20px;
    position: absolute;
    bottom: 40px;
    right: 50px; }
    .guests-actions .verify-button-group .btn {
      font-size: 70px;
      padding: 0px; }
    .guests-actions .verify-button-group .btn.btn-failed {
      width: 75px;
      height: 75px; }
    .guests-actions .verify-button-group .btn.btn-verified {
      padding-left: 5px; }
  .guests-actions .exclamation-icon-container {
    background-color: #fccb2d;
    border-radius: 50%;
    height: 100%;
    font-size: 50px;
    color: white; }
  .guests-actions .check-icon-container {
    position: relative;
    background-color: #35d698;
    border-radius: 100%;
    width: 75px;
    height: 75px; }
  .guests-actions .la.la-check {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

.guest-details-box {
  background-color: #5B5B5B;
  color: white;
  width: 100%;
  height: 100%; }
  .guest-details-box .passport-images {
    width: 100%;
    height: 100%;
    position: relative; }
    .guest-details-box .passport-images .image-gallery {
      height: 100%; }
    .guest-details-box .passport-images #img-nopassportimages {
      width: 200px;
      height: 200px;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .guest-details-box .passport-images .image-gallery-bullets {
      width: 100%; }
    .guest-details-box .passport-images .image-gallery-bullets-container {
      position: absolute;
      bottom: 37px;
      right: 17px !important; }
    .guest-details-box .passport-images .image-gallery-bullet {
      display: block;
      height: 40px;
      width: 40px;
      border: 1px solid #4d4d4d;
      margin-top: 5px;
      background-color: #4d4d4d;
      box-shadow: none; }
      .guest-details-box .passport-images .image-gallery-bullet::before {
        counter-increment: section;
        content: counter(section);
        color: #636363; }
      .guest-details-box .passport-images .image-gallery-bullet.active {
        background-color: #2d2d2d;
        color: #f9f9f9; }
        .guest-details-box .passport-images .image-gallery-bullet.active::before {
          color: #f9f9f9; }
    .guest-details-box .passport-images .image-gallery-content {
      height: 100%; }
      .guest-details-box .passport-images .image-gallery-content .image-gallery-slide-wrapper,
      .guest-details-box .passport-images .image-gallery-content .image-gallery-swipe,
      .guest-details-box .passport-images .image-gallery-content .image-gallery-slides,
      .guest-details-box .passport-images .image-gallery-content .image-gallery-slide,
      .guest-details-box .passport-images .image-gallery-content .image-gallery-image {
        background-color: #5B5B5B;
        height: 100%;
        text-align: center; }
      .guest-details-box .passport-images .image-gallery-content img {
        height: 100%;
        width: auto; }
    .guest-details-box .passport-images .image-gallery-bullets-container {
      counter-reset: section; }

.call-screen .row-3 {
  position: relative;
  background-color: #303030; }

.operator-video.col-md-6 {
  height: 100%; }

#remote-video {
  width: 100%;
  height: 100%;
  background: black;
  padding-left: 0px;
  padding-right: 0px; }

#operator-video-supportscreen, #operator-video-checkinscreen {
  width: 100%;
  height: 100%;
  background: #636363; }

#operator-video-checkinscreen {
  width: 80%;
  height: 80%; }

#remote-video video, #operator-video-supportscreen video, #operator-video-checkinscreen video {
  border: 1px solid #272727;
  height: 100%;
  width: 100% !important;
  background-color: #272726;
  background-repeat: no-repeat; }

#loading {
  display: block;
  height: 100%; }

#loading div {
  margin: auto; }

.call-controls {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 80px auto; }

.btn-show-msg {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAAAXNSR0IArs4c6QAAAUVJREFUWAntlzFqAkEUhjNrSKythHSewCukt0lu4Ak8iyfwCDZa24iBVOkDggmCVYhWIsj6/cu+LcIGMrK7k2Ie/LyZWXb+b96OMuNu8kjTtENzgHqoixyqOlIm3KE1mjnnvmSQGQHQpz1EbQ02FEd8JoC8JXkFmgbQOrXgofwTGvoETVYAuyLkOxCE9kDI6AlCmzBkdAVRx6/AZ1FOEMEjQtgniJWIlbAKWI57IlbCKmA57ol/V4mDEQXKB+2JTSBzs90IYmm9QHmZ6MiN+UsggJX8s6Mdx27lR/SE7hoAOuExRQsgMvPCE5gWnQekw6/vf8g97zyj364PO57NkfIW8zM5i1trKOcPPmhK3sEiPnlphMpA9sz/Wjap72rL5ijGMNEdc4x0xftzVAoh12tAKoe4BqQWCF+Q2iB+gHzTf9dYWVwA2p9Dbn5yYXEAAAAASUVORK5CYII=);
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  border: 1px solid #FFFFFF;
  border-radius: 100%;
  background-color: transparent;
  opacity: 0.6;
  width: 70px;
  height: 70px;
  margin-right: 20px; }

.btn-show-msg:hover {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAAAXNSR0IArs4c6QAAARtJREFUWAntlzEOgjAUhqnGzXgEZhd2buDKSTwCLg4eguhNnB3YMNHdhVXjJqb+P/LcSJQUnkNf8vOgpPxfXxvSBkET1toQyqAjVEF9BL/L79MnFO86oyGBrtCQQb+EAAY3JCqgGRsGjhv8ohEuKaQBwPHSNyVEzCfFiDkdFQDGihBPQlhFgNqa06EeHkKmwFfCV0IqINmvCV8JqYBkvyb+rhKlECnlkmsiVzIX25wQmTwp5bc/Nlc77rAUYvsZOMy5zVtC94FA6EM/Q4j6IjRonOA+gubQr5vfKfpsoLbjwwnv1tAZKowxD2T3gUHEUNtJbt/m6PSPidEdYLSAeLL6OpxC0LULiHOILiC9QHQBYZ/eolmsF+RVm8kLHGkrufjV1oYAAAAASUVORK5CYII=);
  opacity: 1;
  outline: none; }

.btn-show-msg.btn-clicked {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAAAXNSR0IArs4c6QAAAQlJREFUWAntly0OAjEQhRcIwZA9wmoMfjkFnIQrYBAYbkDgJpwABwl4zFoSHBB4r+yEn7QC0Q5iXvI6LQl9X6YV3Sx7qcB0Ae/gK3yPYO7L/ZnDvA+NsDrBMYJDezKPuU4kSg0gYMwtWhjm8ADWUAeheRNDqZH+llk2sOBlYUe0dCMEz0dVPA51GYQcgXXCOiEdkGp3wjohHZBqd+LvOlEJkVKteCc2SuES6/KHWMnDU6My32mFUQNgWee7wmfeGD7DKWCYwzzmPgdOarVR+3AP/vXx28V/ZnAO+7THj1P4AG/hCxxF/HwIfUitoyQGNg2BJIUgmw8kOYQPRAXiG0QNQkCOmEy48OkBSanWmIP0kAQAAAAASUVORK5CYII=);
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat; }

.btn-clicked {
  background: #FFFFFF;
  opacity: 1; }

.btn-clicked .la-comment, .btn-screenshot:active .la-camera {
  color: #363636; }

#passport {
  position: relative;
  height: 100%; }
  #passport .no-data-img {
    margin: auto; }

.passport-image {
  width: 100%;
  /* max-height: 100%; */ }

.la-comment, .la-close, .la-check, .la-camera {
  font-family: LineAwesome;
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  color: #FFFFFF; }

.btn-unverify, .btn-verify {
  border-radius: 100%;
  border: none;
  width: 70px;
  height: 70px;
  margin-right: 20px; }

.btn-unverify {
  background-color: #D15454; }

.btn-verify {
  background-color: #6DD230; }

.btn-screenshot {
  border-radius: 100%;
  background-color: transparent;
  border: 1px solid #FFFFFF;
  width: 70px;
  height: 70px;
  opacity: 0.6; }

.btn-end-call:hover, .btn-screenshot:hover {
  opacity: 1;
  outline: none; }

.btn-screenshot:active {
  background-color: #FFFFFF; }

.btn-call-screen {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.toast-notification {
  margin-top: auto !important;
  margin-left: 60% !important; }

.toast-notification span {
  background: #6DD230 !important;
  color: #FFFFFF !important;
  font-size: 16px;
  font-weight: 400;
  border-radius: 2px !important;
  right: 400px !important; }

.right-btn-end-call {
  width: 100%;
  height: 100%;
  background-color: #2b2d2d; }

.right-btn-end-call button {
  width: 60px; }

.row.row-3 button#sidebar-btn {
  margin-right: 25px;
  background-color: #838C93;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: absolute;
  right: 50px;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .row.row-3 button#sidebar-btn .la-comment {
    font-size: 30px; }

.col-md-6.px-0.video-with-guest-action-container {
  height: 100%; }

.callscreen-sidebar.open {
  position: absolute;
  height: 100vh;
  right: 0px;
  width: 25%;
  display: block; }

.callscreen-sidebar {
  display: none; }

.callscreen-main {
  width: 75%; }

.callscreen-main.full {
  width: 100%; }

#listing-short-name-container {
  position: absolute;
  left: 130px;
  font-size: 25px;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #FFFFFF; }

.container-cameranotavailable {
  width: 60%;
  height: 60%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .container-cameranotavailable img {
    max-width: 100%;
    height: 80%;
    display: block;
    margin: auto; }
  .container-cameranotavailable p {
    text-align: center;
    height: 20%;
    margin: 0;
    font-size: 2vh;
    color: white; }

