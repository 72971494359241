.call-history {
  margin-bottom: 20px;
  height: calc(100% - 120px);
  background-color: #FFFFFF;
  .text-center {
    word-break: break-word;
  }

  .detail-content {
    float: left;
    width: calc(100% - 293px);
    height: 100%;
    overflow-y: auto;
  }
  .right-tab {
    ul {
      padding-left: 0px;
    }
    float: right;
    width: 293px;
    border-left: 1px solid #F2F4F6;
    height: 100%;
  }
  .right-tab li {
    color: #252631;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    padding-left: 20px;
  }
  .la-user, .la-building-o, .la-map-pin, .la-arrow-left {
    color: #778CA2;
    font-family: LineAwesome;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
  }
  .la-arrow-left {
    margin-right: 20px;
  }
  .right-tab .li-select {
    color: #F4364C;
    background-color: #F8FAFB;
    border-left: none
  }
  .listing-name {
    height: 70px;
    color: #252631;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    align-items: center;
    padding-left: 20px;
  }
  .detail-content hr {
    margin-top: 0px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    color: #F2F4F6;
  }
  .table-guest-infos .thead {
    padding-bottom: 10px;
  }
  .call-history-detail .tvalue {
    color: #252631;
    font-size: 16px;
    font-weight: 400;
  }
  .table-guest-infos .thead {
    color: #98A9BC;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
  .table-guest-infos .tvalue {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .reservation-infos, .listing-infos, .table-guest-infos{
    margin-top: 25px;
    .reservation-datetime-info {
      font-size: 24px;
    }
  }
  .listing-infos .col-md-12, .listing-infos .col-lg-12 {
    margin-bottom: 15px;
  }
  .reservation-infos .col-md-6, .reservation-infos .col-lg-6 {
    margin-bottom: 50px !important;
  }
  .listing-infos .col-md-6, .reservation-infos .col-lg-6 {
    margin-bottom: 50px !important;
  }
  .listing-infos .title {
    color: #98A9BC;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }
  .listing-infos .value {
    color: #252631;
    font-size: 24px;
  }

  .tab-detail {
    padding-left: 15px;
    padding-right: 17px;
    height: calc(100% - 82px);
    .table-guest-infos {
      margin-top: 0;
      .col-value {
        font-size: 14px;
        font-weight: 300;
      }
    }
  }

  .table td, .table th {
      padding: .75rem;
      vertical-align: top;
      border-top: 1px solid #dee2e6;
      font-size: 18px;
  }

  .table-guest-infos .thead {
    color: #98A9BC;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }

  .table-guest-infos .tvalue {
    font-size: 18px;
    margin-bottom: 15px;
  }

  table.call-log-list {
    th {
      color: #aab8c8;
      font-size: 14px;
    }

    td .checkin {
      width: 80%;
      height: 80%;
      text-align: center;
      padding: 5px;
      background-color: #f6ebf4;
      color: #f95da1;
      font-size: 14px;
    }

    td .support {
      width: 80%;
      height: 80%;
      text-align: center;
      padding: 5px;
      background-color: #f1f5ff;
      color: #7da0ff;
      font-size: 14px;
    }

    ul {
      padding-left: 0px;
      list-style-type: none;
    }
  }

  .no-data, .load-data {
    text-align: center;
    margin-top: 30px;
    color: #98a9bc;
    font: bold 20px Roboto;
  }
}
