.guests-actions {
  color: #9A9A9A;
  font-weight: 300;
  padding: 30px 15px;
  height: 100%;
  .title {
    font-size: 18px;
  }

  .guests-list {
    max-height: calc(100% - 25px);
    width: 60%;

    .guest-name {
      text-overflow: ellipsis;
      width: calc(100% - 32px);
      overflow: hidden;
      white-space: nowrap;
      float: right;
      line-height: 2.2;
    }
  }

  .guests-list.sidebaropen {
    max-height: calc(100% - 25px);
    width: 50%;

    .guest-name {
      text-overflow: ellipsis;
      width: calc(100% - 32px);
      overflow: hidden;
      white-space: nowrap;
      float: right;
      line-height: 2.2;
    }
  }

  .verify-button-group {
    font-size: 20px;
    position: absolute;
    bottom: 40px;
    right: 50px;
    .btn {
      font-size: 70px;
      padding: 0px;
    }

    .btn.btn-failed {
      width: 75px;
      height: 75px;
    }

    .btn.btn-verified {
      padding-left: 5px;
    }
  }


  .exclamation-icon-container {
    background-color: #fccb2d;
    border-radius: 50%;
    height: 100%;
    font-size: 50px;
    color: white
  }

  .check-icon-container {
    position: relative;
    background-color: #35d698;
    border-radius: 100%;
    width: 75px;
    height: 75px;
  }

  .la.la-check {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}