.detailed-item {
    padding: 20px;
    height: 92%;
    background-color: #F8FAFB;
    
    .row.detailed-item-header {
        height: 8%;
        background-color: white;
        .svg-inline--fa.fa-w-14 {
            font-size: 20px;
        }
        border-bottom: 1px solid #e5e5e5;
    }

    .detailed-item-content {
        height: 92%;
    }
}