.guest-details-box {
  background-color: #5B5B5B;
  color: white;
  width: 100%;
  height: 100%;
  .passport-images {
    width: 100%;
    height: 100%;
    position: relative;
    .image-gallery  {
      height: 100%;
    }

    #img-nopassportimages {
      width: 200px;
      height: 200px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .image-gallery-bullets {
      width: 100%;
    }

    .image-gallery-bullets-container {
      position: absolute;
      bottom: 37px;
      right: 17px !important;
    }

    .image-gallery-bullet {
      display: block;
      height: 40px;
      width: 40px;
      border: 1px solid #4d4d4d;
      margin-top: 5px;
      background-color: #4d4d4d;
      box-shadow: none;
      &::before {
        counter-increment: section;
        content: counter(section);
        color: #636363;
      }


      &.active {
        background-color: #2d2d2d;
        color: #f9f9f9;
        &::before {
          color: #f9f9f9;
        }
      }
    }

    .image-gallery-content {
      height: 100%;
      .image-gallery-slide-wrapper,
      .image-gallery-swipe,
      .image-gallery-slides,
      .image-gallery-slide,
      .image-gallery-image {
        background-color: #5B5B5B;
        height: 100%;
        text-align: center;
      }
      img {
        height: 100%;
        width: auto;
      }
    }
    .image-gallery-bullets-container {
      counter-reset: section;
    }
  }
}