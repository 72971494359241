.call-screen .row-3 {
  position: relative;
  background-color: #303030;
}

.operator-video.col-md-6 {
  height: 100%;
}

#remote-video {
  width: 100%;
  height: 100%;
  background: black;
  padding-left: 0px;
  padding-right: 0px;
}

#operator-video-supportscreen, #operator-video-checkinscreen {
  width: 100%;
  height: 100%;
  background: #636363
}

#operator-video-checkinscreen {
  width: 80%;
  height: 80%;
}

#remote-video, #operator-video-supportscreen, #operator-video-checkinscreen {
  video {
    border: 1px solid #272727;
    height: 100%;
    width: 100% !important;
    background-color: #272726;
    background-repeat: no-repeat;
  }
}

#loading {
  display: block;
  height: 100%;
}

#loading div {
  margin: auto;
}
.call-controls {
  display: flex;
  justify-content: center;
  margin: 80px auto;
}

.btn-show-msg {
  background-image: url('../../images/MessageNormal.png');
  background-position: center; /* Center the image */
  background-repeat: no-repeat;
  border: 1px solid #FFFFFF;
  border-radius: 100%;
  background-color: transparent;
  opacity: 0.6;
  width: 70px;
  height: 70px;
  margin-right: 20px;
}
.btn-show-msg:hover {
  background-image: url('../../images/MessageHover.png');
  opacity: 1;
  outline: none;
}

.btn-show-msg.btn-clicked {
  background-image: url('../../images/MessageActive.png');
  background-position: center; /* Center the image */
  background-repeat: no-repeat;
}

.btn-clicked {
  background: #FFFFFF;
  opacity: 1;
}
.btn-clicked .la-comment, .btn-screenshot:active .la-camera {
  color: #363636;
}

#passport {
  position: relative;
  height: 100%;
  .no-data-img {
    margin: auto;
  }
}
.passport-image {
  width: 100%;
  /* max-height: 100%; */
}
.la-comment, .la-close, .la-check, .la-camera {
  font-family: LineAwesome;
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  color: #FFFFFF;
}
.btn-unverify, .btn-verify {
  border-radius: 100%;
  border: none;
  width: 70px;
  height: 70px;
  margin-right: 20px;
}
.btn-unverify {
  background-color: #D15454;
}
.btn-verify {
  background-color: #6DD230;
}
.btn-screenshot {
  border-radius: 100%;
  background-color: transparent;
  border: 1px solid #FFFFFF;
  width: 70px;
  height: 70px;
  opacity: 0.6;
}
.btn-end-call:hover, .btn-screenshot:hover {
  opacity: 1;
  outline: none;
}
.btn-screenshot:active {
  background-color: #FFFFFF
}
.btn-call-screen {
  display: flex;
  align-items: center;
}
.toast-notification {
  margin-top: auto !important;
  margin-left: 60% !important;
}
.toast-notification span {
  background: #6DD230 !important;
  color: #FFFFFF !important;
  font-size: 16px;
  font-weight: 400;
  border-radius: 2px !important;
  right: 400px !important;
}

.right-btn-end-call {
  width: 100%;
  height: 100%;
  background-color: #2b2d2d;
}

.right-btn-end-call button {
  width: 60px;
}

.row.row-3 button#sidebar-btn {
  .la-comment {
    font-size: 30px;
  }
  margin-right: 25px;
  background-color: #838C93;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;

  position: absolute;
  right: 50px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.col-md-6.px-0.video-with-guest-action-container {
    height: 100%;
}
.callscreen-sidebar.open {
  position: absolute;
  height: 100vh;
  right: 0px;
  width: 25%;
  display: block
}

.callscreen-sidebar {
  display: none
}


.callscreen-main {
  width: 75%;
}

.callscreen-main.full {
  width: 100%
}

#listing-short-name-container {
    position: absolute;
    left: 130px;
    font-size: 25px;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #FFFFFF;
}

.container-cameranotavailable {
  width: 60%;
  height: 60%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  img {
    max-width: 100%;
    height: 80%;
    display: block;
    margin: auto;
  }

  p {
    text-align: center;
    height: 20%;
    margin: 0;
    font-size: 2vh;
    color: white;
  }
}
