.guest-item {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  width: 98%;
  height: 50px;
  font-size: 22px;
  &.active {
    background-color: white;
    color: #4F5B65;
    font-weight: bold;
  }
  .guest-status {
    font-size: 24px;
    line-height: 2;
  }
}