
.App {
    text-align: center;
    font-size: 12px;
}
.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}
.btn-logout, .btn-submit {
    background-color: #f44336;
    color: #ffffff;
    padding: 15px;
}
.row {
    margin-left: 0px;
    margin-right: 0px;
}
.btn-logout {
    width: 100px;
    font-size: 14px;
}
.form-control {
    font-size: 14px;
    height: 30px;
    padding: 5px;
}
@keyframes App-logo-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}
.page-wrapper {
    font-size: 14px;
}

#root .color-dark {
  color: #778CA2;
}

#root .color-black {
  color: #212529;
}

#root .line-height-15 {
  line-height: 15px;
}

#root .line-height-16 {
  line-height: 16px;
}

#root .line-height-24 {
  line-height: 24px;
}
/* html { min-height: 100%; position: absolute; top: 0; bottom: 0; left: 0; right: 0; overflow:hidden;}
body { min-height: 100%; overflow:hidden;} */

.scrollbar-custom {
  overflow: auto;
}

.scrollbar-custom::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scrollbar-custom::-webkit-scrollbar:vertical {
  width: 0px;
}

.scrollbar-custom::-webkit-scrollbar:horizontal {
  height: 0px;
}

.scrollbar-custom:hover::-webkit-scrollbar:vertical {
  width: 7px;
}

.scrollbar-custom:hover::-webkit-scrollbar:horizontal {
  height: 7px;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 1px solid transparent; /* should match background, can't be transparent */
  background-color: #5B5B5B;
}

.scrollbar-custom::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 8px;
}

.bg-black {
  background-color: #000;
}

.bg-black.opacity-08 {
  background-color: rgba(0,0,0,0.6);
}

.opacity-08 {
  opacity: 0.8
}

.bg-submit {
  background-color: #f44336;
}