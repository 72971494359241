.new-list-call {
  height: 100%;
  width: 100%;
}
.sidebar {
  /* width: 15%; */
  border-right: 1px solid #E8ECEF;
  /* float: left; */

  position: absolute;
  /* top: 0px; */
  left: -230px;
  height: 100%;
  width: 300px;
  transition: left .3s ease-in-out;
}
.content {
  position: absolute;
  background: #F8FAFB;
  /* top: 0; */
  left: 70px;
  right: 0;
  height: 100%;
  transition: left .3s ease-in-out;
}
.header {
  width: 100%;
  height: 8%;
  border-bottom: 1px solid #E8ECEF;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  font-size: 20px;

  .operator-name {
    color: #F4364C;
    text-align: right;
    height: 100%;
  }

  .search-for-listing {
    .searh-form {
      font-size: 16px;
      outline: none;
      height: 50px;
      &:focus {
        box-shadow: none;
      }
    }

    .la {
      font-size: 24px;
      &.la-search {
        line-height: 40px;
      }
    }

  }
}

.zen-logo-new {
  width: 50%;
  margin-left: 20%;
  margin-right: 35%;
  margin-top: 30px;
  margin-bottom: 40px;
}
.zen-logo-small {
  float: right;
  width: 50px;
  height: 50px;
  margin-left: 240px;
  margin-top: 15px;
  margin-bottom: 40px;
}
.left-230 {
  margin-left: 230px;
}
.header svg {
  width: 50px !important;
  height: 20px;
  color: #778CA2;
}

.header-title, .operator-name {
  color: #252631;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}


.list-place .operator-name {
  color: white;
}
.operator-name:hover, .operator-name:focus {
  outline: none !important;
  box-shadow: none !important;
}
.sidebar-open {
  left: 0;
}
.content-open {
  left: 300px;
}
.sidebar ul {
  padding: 0px;
  list-style-type: none;
}
.sidebar ul li {
  /* background-color: #F8FAFB; */
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  padding-left: 20px;
  color: #778CA2;
  font-size: 14px;
  font-weight: 400;
}
.sidebar ul li:hover {
  background-color: #F8FAFB;
}
.li-select {
  border-left: 2px solid #F4364C;
  background-color: #F8FAFB;
  font-weight: 500 !important;
}
.li-select .la::before {
  color: #F4364C !important;
}
.tab-content {
  padding: 20px;
  height: 92%;
  background-color: #F8FAFB;
}
.hidden {
  display: none;
}
.tab-title {
  color: #778CA2;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 15px;
}
.select {
  background-color: #F4364C !important;
  color: #FFFFFF !important;
}
.btn-calling-type {
  background-color: #F8FAFB;
  width: 144.76px;
  height: 35.22px;
  color: #778CA2;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  border-radius: 5px;
  padding: 3.75px 7.5px;
}
.incoming-call hr {
  margin-top: 20px;
}
.table-list-calls .thead {
  color: #98A9BC;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 10px;
}
.table-list-calls .tvalue {
  background-color: #FFFFFF;
  min-height: 60px;
  border-radius: 4px;
  margin-bottom: 10px;
  color: #252631;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .text-center {
    word-break: break-word
  }
}
.btn-accept-call, .btn-detail-call {
  height: 34px;
  padding: 0;
  border: none;
  color: transparent;
  background-color: transparent;
  outline: none;
  display: block;
}
.btn-accept-call {
  float: left;
  background-color: #6DD230;
  width: 80%;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: center;
  border-radius: 5px;
}
.type-checkin, .type-support {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: center;
  width: 121px;
  height: 34px;
  margin: 0 auto;
  padding: 8px;
}
.type-support {
  color: #4D7CFE;
  background-color: #F0F4FE;
}
.type-checkin {
  color: #FE4D97;
  background-color: #FFF1F6;
}
.la {
  font-family: LineAwesome;
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
}
.la-phone::before, .la-history::before {
  color: #778CA2;
  float: right;
}
.la-info-circle::before {
  color: #252631;
  font-family: LineAwesome;
  font-size: 42px;
  font-weight: 500;
  line-height: 50px;
}
.right-20 {
  margin-right: 20px;
}
.btn-group {
  width: 100%;
}
.btn-detail-call {
  float: right;
  margin-top: -9px;
  margin-left: 10px;
  &.disabled {
    opacity: 0.3;
  }
}
.btn-detail-call-center {
  margin-top: -9px;
  margin-left: 10px;
  border: none;
  background: none;
  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}
.pagination .prev-text, .pagination .next-text {
  text-align: center;
  width: 60px !important;
  padding-left: 0px;
  padding-right: 0px;
  font-size: 14px;
  color: #778CA2;
  font-family: Roboto;
  font-weight: 400;
  line-height: 17px;
  padding-top: 2px;
}
.pagination>li {
  width: 40px;
}
.pagination>li>a {
  border: none !important;
  color: #000000;
}
.la-angle-left, .la-angle-right {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #778CA2;
  font-family: LineAwesome;
  text-align: center;
}
.prev-li {
  margin-right: 30px;
}
.pagination .active a {
  color: #FFFFFF;
  background-color: #F4364C;
  border-radius: 4px;
  width: 40px;
  text-align: center;
}
.pagination>.active>a:hover, .pagination>.active>a:focus{
  background-color: #F4364C;

}
.pagination-call {
  width: 350px;
  margin-left: auto;
  margin-right: auto;
}
.popup-list-call {
  padding-top: 40px;
  padding-left: 50px;
  padding-right: 50px;
  max-width: 500px;
}
.user-menu ul {
  font-size: 14px;
  margin-top: 23px;
  text-align: center;
  left: auto !important;
  right: 0 !important;
  transform: translate3d(-7px, 20px, 0px) !important;
}

.missed-time {
  font-size: 15px;
}
.text-loading {
  font-size: 15px;
}
.noti-bar {
  background: #2F3036;
  color: #FFFFFF;
  padding: 10px;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  align-items: center;
}
.noti-bar span {
  margin-right: 20px;
}
.btn-noti-call {
  margin-right: 15px;
}
.btn-noti-call img {
  width: 40px;
  height: 40px;
}

.time-range-filter, .call-type-filter, .status-filter, .reservation-status-filter, .checkin-status-filter, .tablet-location-filter {
  .dropdown-toggle {
    &:after {content: none;}
  }
  margin: auto;;
}

.col.text-center.nocall-noti {
  color: #98a9bc;
  font: 20px Roboto;
  padding-top: 50px;
  font-weight: bold
}

.popup-remopve-place {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 100px;
  padding-right: 100px;
  border: 1px solid;
  background-color: #fff;
}
.popup-remopve-place h2 {
  text-align: center;
  color: #616161;
  font-weight: bold;
  margin-bottom: 15px;
}
.popup-remopve-place .body {
  text-align: center;
  margin-bottom: 30px;
}
.popup-remopve-place .submit {
  background-color: #f44336;
  color: #fff;
  font-size: 14px;
  margin-right: 20px;
  font-weight: bold;
}

.popup-remopve-place .cancel {
  border: 0.8px solid #616161;
  font-size: 14px;
  width: 108px;
}