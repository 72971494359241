.tablet-location-container {
  p {
    margin-bottom: 0;
  }

  padding: 20px;
  height: 92%;
  background-color: #F8FAFB;

  .row.flash-messages {    
    .alert {
      width: 100%;
      text-align: center;
      margin: 0;
      p {
        margin: 0;
      }
    }
    position: absolute;
    left: 5%;
    width: 90%;
    z-index: 1;
  }

  .add-place {
    background-color: white;
    
    .header-add-place {
      border-bottom: 1px solid rgb(222, 224, 225);
      padding: 10px 0px 10px 0px;
      height: 7%;
    }

    .generate-code-btn {
      background: none;
      border: none;
    }

    .form-add-place {
      width: auto;
      padding-top: 30px;
      height: 93%;
    }
    .form-add-place .form-group {
      margin-bottom: 40px;
    }
    
    #place-type {
      width: 60%;
    }
    .input-place {
      width: 100% !important;
    }
    .setup-code {
      background: #616161;
      padding: 10px;
      padding-left: 20px;
      padding-right: 20px;
      color: white;
      margin-right: 10px;
    }
    #refresh-code {
      width: 25px;
    }
    
    .btn-place-type {
      width: 100px;
      font-size: 14px;
      background-color: white;
      color: black;
    }

    .btn-place-type.active {
      color: #fff;
      background-color: #0062cc;
      border-color: #005cbf
    }
    
    .btn-place-type.disabled {
      background: rgba(0,0,0,0.25);
      color: black;
    }
    .btn-add {
      width: 100px;
      background-color: #FA7268;
      padding: 10px;
      color: white;
      margin-right: 10px;
      margin-bottom: 40px;
      font-size: 14px;
    }
    .btn-clear {
      width: 100px;
      border: 1px solid;
      padding: 10px;
      margin-right: 10px;
      font-size: 14px;
    }
    .place-title {
      margin-bottom: 40px;
      color: #616161;
    }
    .add-place-title {
      font-weight: bold;
    }
  }

  .edit-place {
    background-color: white;

    .generate-setup-code-btn {
      border: none;
      background: none;
    }

    .cannot-edit-place {
      width: 50%;
      height: 93%;
      margin: auto;
      text-align: center;
      display: flex;
      p {
        margin: auto;
        font-family: roboto;
        font-size: 20px;
        color: red;
        text-shadow: 2px -4px yellow;
      }
    }
  
    .header-edit-place {
      border-bottom: 1px solid rgb(222, 224, 225);
      padding: 10px 0px 10px 0px;
      height: 7%;
      font-size: 20px;
    }
    
    .form-add-place {
      width: auto;
      padding-top: 30px;
      height: 93%;
    }
    .form-add-place .form-group {
      margin-bottom: 40px;
    }
    
    #place-type {
      width: 60%;
    }
    .input-place {
      width: 100% !important;
    }
    .setup-code {
      background: #616161;
      padding: 10px;
      padding-left: 20px;
      padding-right: 20px;
      color: white;
      margin-right: 10px;
    }
    #refresh-code {
      width: 25px;
    }
    
    .btn-place-type {
      width: 100px;
      font-size: 14px;
      background-color: white;
      color: black;
    }
    
    .btn-place-type.disabled {
      background: rgba(0,0,0,0.25);
      color: black;
    }
    .btn-add {
      width: 100px;
      background-color: #FA7268;
      padding: 10px;
      color: white;
      margin-right: 10px;
      margin-bottom: 40px;
      font-size: 14px;
    }
    .btn-clear {
      width: 100px;
      border: 1px solid;
      padding: 10px;
      margin-right: 10px;
      font-size: 14px;
    }
    .place-title {
      margin-bottom: 40px;
      color: #616161;
    }
    .add-place-title {
      font-weight: bold;
    }
  }

  .place-detail {
    overflow: auto;

    background-color: white;
    width: 100%;

    .header-place-detail {
      border-bottom: 1px solid rgb(222, 224, 225);
      padding: 10px 0px 10px 0px;
      height: 7%;
      font-size: 20px;
    }
    .place-detail-title {
      color: #616161;
      margin-bottom: 20px;
    }
    .react-bootstrap-table th {
      background-color: #d4e0f7;
      color: #000000;
      border: 1px solid #e5ebef;
      font-size: larger;
    }

    .react-bootstrap-table {
      padding: 30px;
    }

    .edit-place-btn{
      margin-bottom: 30px;
      margin-right: 30px;
      background-color: #4a7ddc;
      color: white;
      border: none;
      width: 100px;
      height: 40px;
      border-radius: 7px;
    }
  }

  #tablet-location-list {
    height: 100%;
    .table-list {
      height: 88%;
      .thead {
        color: #98A9BC;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        margin-bottom: 10px;
      }
      .tcontent {
        height: 99%;
        overflow-y: auto;
      }
      .tvalue {
        background-color: #FFFFFF;
        min-height: 60px;
        border-radius: 4px;
        margin-bottom: 10px;
        color: #252631;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        display: flex;
        flex-direction: row;
        align-items: center;
    
        .text-center {
          word-break: break-word
        }
      }    
      .btn-group {
        button {
          border-radius: 50%;
          border: none;
          width: 40px;
          height: 40px;
          margin: 2px;
        }

        .call-btn {
          background-color: #e5f7da;
          svg {
            color: #6DD230;
          }
        }

        .edit-btn {
          background-color: #d4e0f7;
          svg {
            color: #4a7ddc;
          }
        }

        .delete-btn {
          background-color: #fee7e9;
          svg {
            color: #f4354b;
          }
        }
      }
    
      .tvalue.row.tablet-location-element:hover { 
          border: 1px solid orangered;
          cursor: pointer;
      }
      .tvalue.row.tablet-location-element {
        border: 1px solid white
      }
    }

    .add-location-container {
      padding-left: 0px;
    }
  
    .row .col-md-6 .d-flex.float-right {
      width: 100%;
    }
  
    .searchbar {
      .search-form {
        font-size: 16px;
        outline: none;
        height: 50px;
        &:focus {
          box-shadow: none;
        }
      }
  
      .la {
        font-size: 24px;
        &.la-search {
          line-height: 40px;
        }
      }
    }
  }
}

.tablet-location-container.hidden {
  display: none;
}

.tablet-location-alert {
  position: relative;
  font-family: Roboto;
  text-align: center;
  width: 650px;
  padding: 40px;
  background: #ffffff;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);

  .title {
    margin: 30px;
    font-size: 20px
  }

  .btn-cancel {
    position: absolute;
    top: 0px;
    right: 0px;
    background: none;
    border: none;

    .la.la-close {
      color: black;
    }
  }

  .btn-call {
    width: 160px;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    border: none
  }

  .btn-call.checkin {
    background: red;
  }

  .btn-call.support {
    background: #4A7DDC;
  }
}

.popup-remove-place {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 100px;
  padding-right: 100px;
  border: 1px solid;
  background-color: #fff;
}
.popup-remove-place h2 {
  text-align: center;
  color: #616161;
  font-weight: bold;
  margin-bottom: 15px;
}
.popup-remove-place .body {
  text-align: center;
  margin-bottom: 30px;
}
.popup-remove-place .submit {
  background-color: #f44336;
  color: #fff;
  font-size: 14px;
  margin-right: 20px;
  font-weight: bold;
}
.popup-remove-place .confirm {
  background-color: #4A7DDC;
  color: #fff;
  font-size: 14px;
  margin-right: 20px;
  font-weight: bold;
}
.popup-remove-place .cancel {
  border: 0.8px solid #616161;
  font-size: 14px;
  width: 108px;
}

.no-data, .load-data {
  text-align: center;
  margin-top: 30px;
  color: #98a9bc;
  font: bold 20px Roboto;
}