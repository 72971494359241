button.logout-btn {
    background: none;
    border: none;
    padding: 0;
    color: #007BFF;
}
  
button.logout-btn:hover {
  text-decoration: underline;
  color: #0056B3;
} 