.row-1 .end-button-box {
  margin-top: 20px;
}

#num-guets-verified {
  p {
    margin: 0px;
  }
  position: absolute;
  top: 50%;
  left: 110px;
  transform: translate(-50%, -50%);
  color: yellow;
  font-weight: bold;
  font-size: 18px;
}

.row-1 .guest-name {
  text-align: center;
  width: 100%;
  color: #FFFFFF;
  font-family: monospace;
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  margin: 0 auto;
  text-overflow: ellipsis;
}

.row-1 .duration {
  text-align: center;
  width: 100%;
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 300;
  line-height: 26px;
  opacity: 0.6994512648809523;
  margin: 0 auto;
}

.row-1 .verification-noti {
  position: absolute;
  right: 30px;
  top: 20px;
  padding: 0px;
  margin: 0px;
  color: white;
  padding: 10px;
  background-color: #35d698;
  border-radius: 5px;
}

.row-1 .btn-end-call {
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: transparent;
  border: none;
  background-size: 100px auto;
  margin-top: 0px !important;
  text-align: left;
}

.row-1 .end-button-box {
    margin-top: 17px;
}

.row-1 .btn-end-call img {
  width: 60px;
  height: 60px;
}

.row-1 .topbar-box {
  background-color: #2b2d2d;
  width: 100%;
  position: relative;
}

.d-flex.button-box {
    margin-left: 45px;
}

.row-1 button.swipe-call-details {
  .la-comment {
    color: #303030
  }
  background-color: #FFFFFF;
  border: none;
  position: absolute;
  right: 69px;
  top: 50%;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  padding: 0px;
  transform: translate(-50%, -50%);
}

.microphone-unavailable {
  width: 241px;
  height: 30px;
  color: yellow;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: large;
}

.microphone-unavailable.left {
  left: 10%;
}

.microphone-unavailable.right {
  right: -4%;
}
