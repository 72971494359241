.messenger {
  padding: 0px 15px 0px 15px;
  height: 93vh;
  background-color: #ffffff;
}

.messages-wrapper {
    display: table-cell;
    vertical-align: bottom;
    height: 86vh;
    width: 25vw;
}
.history-msg {
  position: relative;
  max-height: 86vh;
  overflow: auto;
}
.line-msg {
  width: 100%;
  min-height: 70px;
  overflow: auto;

  .line-msg-container {
    max-width: 75%;
    height: 100%;
    border: none;
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    word-break: break-word;
    margin-bottom: 8px;
  }

  .line-msg-container.tablet {
    color: #ffffff;
    background-color: #5B5B5B;
    border-radius: 20px 20px 20px 0px;
  }

  .line-msg-container.operator {
    color: black;
    background-color: #d2d2d2;
    border-radius: 20px 20px 0px 20px;
  }

}

.input-messages {
  width: 100%;
  height: 7vh;
  padding-top: 5px;

  .type-msg {
    border: none;
    padding: 5px;
    padding-left: 20px;
    height: 50px;
    color: #ffffff;
    background-color: #bebebe;
    float: left;
    width: 90%;
    border-radius: 10px 0px 0px 10px;
    padding-right: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }

  .btn-send-message {
    height: 50px;
    float: left;
    width: 10%;

    button {
      width: 100%;
      height: 100%;
      background-color: #bebebe;
      border: 0;
      border-radius: 0px 10px 10px 0px;
    }
  }
}

.type-msg:focus {
  outline: none;
}

.history-msg div::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
  background-color: #333434;
  margin-left: 10px;
}

.history-msg div::-webkit-scrollbar {
	width: 8px;
	background-color: #333434;
}

.history-msg div::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #8D8D8D;
}

.msg-content {
  width: 100%;
  margin-bottom: 10px;
}
.msg-time {
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  margin-right: 5px;
}
