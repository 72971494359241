.reservations-list.tab-content {
  padding: 20px;
  height: 92%;
  background-color: #F8FAFB;
}

.reservations-list-container {
  height: 100%;
}

.table-reservations {
  height: 95%;
  .table-content {
    height: 95%;
    overflow-y: auto;

    .tvalue.row.high-light {
      background-color: #fef2ca;
    }
  }

  .thead {
    color: #98A9BC;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 10px;
  }
  .tvalue {
    background-color: #FFFFFF;
    min-height: 60px;
    border-radius: 4px;
    margin-bottom: 10px;
    color: #252631;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .text-center {
      word-break: break-word
    }
  }

  .res-status {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: center;
    width: 121px;
    height: 34px;
    margin: 0 auto;
    padding: 8px;
    width: 70%
  }

  .res-status.checkin {
    background-color: #f4fcef;
    color: #77d53e
  }


  .res-status.not {
    color: #FE4D97;
    background-color: #FFF1F6;
  }

  .btn-action-call {
    background-color: #6DD230;
    width: 120px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    text-align: center;
    border-radius: 5px;
    outline: none;
    display: block;
    height: 34px;
    padding: 0;
    border: none;
  }

  .btn-action-call.disabled {
    background-color: #c0c7bb
  }

  .action-reservation-detail {
    margin-top: -9px;
    margin-left: 5px;
    border: none;
    background: none;
    .la-info-circle::before {
      color: #252631;
      font-family: LineAwesome;
      font-size: 42px;
      font-weight: 500;
      line-height: 50px;
    }
  }

  .res-not-found {
    color: #98a9bc;
    font: 20px Roboto;
    padding-top: 50px;
    font-weight: bold
  }

}


.reservation-alert {
  position: relative;
  font-family: Roboto;
  text-align: center;
  width: 650px;
  padding: 40px;
  background: #ffffff;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);

  .title {
    margin: 30px;
    font-size: 20px
  }

  .btn-cancel {
    position: absolute;
    top: 0px;
    right: 0px;
    background: none;
    border: none;

    .la.la-close {
      color: black;
    }
  }

  .btn-call {
    width: 160px;
    padding: 10px;
    margin: 10px;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    border: none
  }

  .btn-call.checkin {
    background: red;
  }

  .btn-call.checkin[disabled] {
    background-color: brown;
    cursor: wait;
  } 

  .btn-call.support {
    background: #4A7DDC;
  }

  .btn-call.support[disabled] {
    background-color: #687fa9;
    cursor: wait;
  }
}