.nav-noti-group {
  max-height: 64px;
}
.noti-bar {
  position: fixed;
  width: 100%;
  z-index: 2222;
  .noti-group-btn {
    .take-phone-icon {
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
    }
  }
}


.my-node-enter {
  max-height: 0;
}
.my-node-enter-active {
  max-height: 64px;
}

.my-node-exit{
  max-height: 64px;
}

.my-node-exit-active {
  max-height: 0;
  transition: max-height 500ms ease-out;
  padding: 0;
  overflow: hidden;
}