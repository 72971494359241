.call-detail {
  background: #FFFFFF;
  width: 100%;
  margin: 0px;
  height: 100vh;
  position: relative;

  .row.header {
    height: 7vh;
    margin-bottom: 0px;
  }

  ul {
    padding: 0px;
    list-style-type: none;
  }
  .btn-call-detail {
    background: #f8fafb;
    box-shadow: 0 0 0 1px #e4f2f9;
    height: 100%;
  }
  .btn-call-detail button {
    font-size: 16px;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
  }
  .btn-call-detail button:focus {
    outline: 0;
    box-shadow: none;
  }
  .btn-call-detail .btn-select {
    background-color: #C3D8E3
  }
  .btn-call-detail .btn i {
    vertical-align: middle;
  }
  .no-left {
    border-left: none !important;
  }
  .table-guest-infos .thead {
    color: #98A9BC;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
  .table-guest-infos .tvalue {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .table-guest-infos {
    p {
      margin-bottom: 0px;
    }
    .guest-occupation {
      color: #98A9BC
    }
  }

  .reservation-infos, .listing-infos, .guests-infos{
    margin-top: 25px;
    .reservation-datetime-info {
      font-size: 18px;
    }
  }
  .listing-infos .col-md-12, .listing-infos .col-lg-12 {
    margin-bottom: 15px;
  }
  .reservation-infos .col-md-6, .reservation-infos .col-lg-6 {
    margin-bottom: 50px !important;
  }
  .listing-infos .col-md-6, .reservation-infos .col-lg-6 {
    margin-bottom: 50px !important;
  }
  .listing-infos .title {
    color: #98A9BC;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin-bottom: 0;
  }
  .listing-infos .value {
    color: #252631;
    font-size: 18px;
    margin-bottom: 0;
  }
  .img-screenshot {
    width: 100%;
    height: 100%;
  }

  .tab-detail {
    width: 100%;
    height: 93vh;
    .information-display, .chat-display {
      display: block
    }

    .information-hidden, .chat-hidden {
      display: none !important;
    }
    .information-display {
      padding-left: 15px;
      padding-right: 15px;
      height: 100%;
      .no-information-state {
        margin: auto;
        p {
          text-align: center;
          margin-top: 20px;
          margin-bottom: 0px;
          font-size: 2vh;
        }
      }

      .anonymous-call-info {
        padding: 2rem;

        h3 {
          margin-bottom: 2rem;
        }

        ul {
            padding-left: 30px;
            list-style: initial;
        }

        ul li {
          margin: 5px;
        }
      }
    }
    .table-guest-infos {
      margin-top: 0;
      .col-value {
        font-size: 14px;
        font-weight: 300;
      }
    }
  }

  .table td, .table th {
      padding: .75rem;
      vertical-align: top;
      border-top: 1px solid #dee2e6;
      font-size: 15px;
  }
}
