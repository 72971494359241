.flash-group {
  position: absolute;
  top: 20px;
  right: 140px;
  z-index: 2222;

  .verify-flash {
    padding: 5px 25px;
    border-radius: 4px;
    color: white;
    width: 220px;

    &.failed {
      background-color: #FFC52C;
    }
    &.verified {
      background-color: green;
    }
  }
}