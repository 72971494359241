.checkbox label {
  padding-left: 0px;
}
.checkbox {
  margin-top: -5px;
}
.forgot-password {
  float: right;
}
#checkbox-signup {
  margin-left: 0px;
}
#lb-checkbox-signup {
  margin-left: 20px
}
.btn-submit {
  width: 215px;
  font-size: 16px;
  font-weight: bold;
}
.zen-logo-login img {
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}
.group-button {
  margin-top: 30px;
}
.login-form {
  float: none;
  margin: 0 auto;
  padding-top: 200px;
  font-size: 14px;
}
.copyright {
  margin-top: 200px;
}
.popup-wrong-role {
  padding-left: 50px;
  padding-right: 50px;
  max-width: 500px;
}
.popup-wrong-role .submit {
  width: 150px;
}

.login-steps {
  max-width: 160px;
}

.login-again-btn {
  background-color: rgba(0,0,0,0.6);
  width: 150px;
}