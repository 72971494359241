.history-call {
  .search-filter-group {
    .time-range-filter {
      #dateRangeDropdown {
        left: auto;
        right: 0;
      }
    }

    .calling-type-list {
      transform: translate3d(0, 23px, 0px) !important;
      transform: translate3d(0, 23px, 0px) !important;
      padding: 0;
      left: auto !important;
      right: 0;
      .dropdown-item {
        padding: 15px;
        &:not(:first-child) {
          border-top: 1px solid rgba(0,0,0,.1);
          border-radius: 0;
        }
      }
    }
  }

  .history-calls-container {
    height: 100%;

    .table-list-calls {
      height: 93%;

      .table-list-content {
        height: 95%;
        overflow-y: auto;
      }
    }
  }

  .table-list-calls .thead {
    color: #98A9BC;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 10px;
  }
  .table-list-calls .tvalue {
    background-color: #FFFFFF;
    min-height: 60px;
    border-radius: 4px;
    margin-bottom: 10px;
    color: #252631;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
  
    .text-center {
      word-break: break-word
    }
  }

  .anonymous-guest {
    color: #FFCB2C;
  }
}