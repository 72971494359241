.rdrDefinedRangesWrapper {
  width: 166px;
  .rdrStaticRanges {
    position: relative;
    height: 100%;
  }
  .rdrStaticRange:last-child span{
    background-color: #F4364C;
    color: white;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
  }
}
.rdrDateRangeWrapper {
  font-size: 12px;
  font-family: 'Roboto';

  .rdrMonth {
    width: 250px;
  }
  .rdrStartEdge, .rdrEndEdge, .rdrInRange {
    border-radius:  0 !important;

  }

  .rdrInRange {
    opacity: 0.2;
  }

  .rdrInRange ~ .rdrDayNumber span{
    color: black !important;
    font-weight: bold;
  }

  .rdrDayPassive {
    .rdrInRange ~ .rdrDayNumber span {
      font-weight: normal;
      color: #aeb9bf !important;
    }
  }

  .rdrMonthAndYearWrapper {
    background-color: #F8FAFB;

    .rdrNextPrevButton, .rdrPprevButton {
      background: transparent;
      color: #98A9BC;
      outline: none;
      i {
        font: normal normal normal 16px/1 LineAwesome;
        font-family: LineAwesome;
        font-size: 22px;
        font-weight: 500;
        line-height: 0;
        border:  none;
        &::before {
          content: "\f111";
        }
      }
    }

    .rdrPprevButton {
      transform: translate(-10px, 0) !important;
    }

    .rdrNextButton {
      transform: translate(-4px, 0) !important;
      i {
        &::before {
          content: "\f112";
        }
      }
    }

    .rdrMonthAndYearPickers {
      font-size: 18px;
      font-weight: 400;

    }
  }
}